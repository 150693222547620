import api from '@apiInstance';

export default {
  add(data) {
    return api.post('pdf_designers', data);
  },
  get(id) {
    return api.fetch('pdf_designers/' + id);
  },
  async list(params) {
    const response = await api.fetch('/pdf_designers', { params });
    return response;
  },
  update(data) {
    return api.patch('pdf_designers', data);
  },
  delete(id) {
    return api.remove('pdf_designers', id);
  },
};
